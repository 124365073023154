import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {ApiClient, create} from '@stryd/api-client';

const ApiContext = createContext<ApiClient | null>(null);

export const ApiContextProvider = ({children}: PropsWithChildren<unknown>) => {
  const [strydApi] = useState(() => {
    const apiClient = create({
      baseURL: process.env.NEXT_PUBLIC_STRYD_API_BASEURL,
    });

    const refreshToken = async () => {
      if (startedRefreshToken.current) return;

      const userRefreshToken = localStorage.getItem('refreshToken');
      const userRefreshTokenClientId = localStorage.getItem('refreshClientId');

      if (!userRefreshTokenClientId || !userRefreshToken) return;

      startedRefreshToken.current = true;

      const res = await apiClient.auth.refreshToken({
        clientId: userRefreshTokenClientId,
        refreshToken: userRefreshToken,
      });

      if (res.data) {
        localStorage.setItem('token', res.data.access_token);
        localStorage.setItem('refreshToken', res.data.refresh_token.token);
        localStorage.setItem(
          'refreshClientId',
          res.data.refresh_token.client.id
        );

        window.location.reload();
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('refreshClientId');
      }
    };

    apiClient.clients.backend.interceptors.response.use(
      (res) => res,
      (error) => {
        if ((error as any).response?.status === 401) {
          refreshToken();
        }
        return Promise.reject(error);
      }
    );

    return apiClient;
  });

  const startedRefreshToken = useRef(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const userToken = localStorage.getItem('token');
      if (userToken) {
        strydApi.setBearerToken(userToken);
      }
    }
  }, [strydApi]);

  return <ApiContext.Provider value={strydApi}>{children}</ApiContext.Provider>;
};

export const useApi = (): ApiClient => {
  const api = useContext(ApiContext);
  if (!api) {
    throw new Error('useApi must be used under ApiContextProvider');
  }
  return api;
};
